import React from 'react'
// import img1 from "../data/image/site-images/img2.jpg"
// import img2 from "../data/image/site-images/img3.jpg"
// import img3 from "../data/image/site-images/img4.jpg"

function Carousel() {
    return (
        <div id="myCarousel" className="carousel slide" data-bs-ride="carousel">
            <div className="carousel-indicators">
                <button type="button" data-bs-target="#myCarousel" data-bs-slide-to={0} className="active" aria-current="true" aria-label="Slide 1" />
                <button type="button" data-bs-target="#myCarousel" data-bs-slide-to={1} aria-label="Slide 2" />
                <button type="button" data-bs-target="#myCarousel" data-bs-slide-to={2} aria-label="Slide 3" />
            </div>
            <div className="carousel-inner">
                <div className="carousel-item bg-dark active">
                    {/* <svg className="bd-placeholder-img" width="100%" height="100%" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" preserveAspectRatio="xMidYMid slice" focusable="false"><rect width="100%" height="100%" fill="#777" /></svg> */}
                    <img src="https://firebasestorage.googleapis.com/v0/b/feed-street.appspot.com/o/feedstreet%2Fbanners%2F0_b1.png?alt=media&token=20dc1b73-f0fc-4eb1-9785-bab5d84c3704" alt="feedstreet" className="bd-placeholder-img opacity-50" width="100%" height="100%" />
                    <div className="container">
                        <div className="carousel-caption text-start">
                            <h1>Paws for Hope: Rescuing and Rehabilitating Street Animals</h1>
                            <p>Dedicated to rescuing and providing a second chance for street animals in need.</p>
                            {/* <p><a className="btn btn-lg btn-primary" href="#">Sign up today</a></p> */}
                        </div>
                    </div>
                </div>
                <div className="carousel-item bg-dark">
                    {/* <svg className="bd-placeholder-img" width="100%" height="100%" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" preserveAspectRatio="xMidYMid slice" focusable="false"><rect width="100%" height="100%" fill="#777" /></svg> */}
                    <img src="https://firebasestorage.googleapis.com/v0/b/feed-street.appspot.com/o/feedstreet%2Fbanners%2F1_b2.png?alt=media&token=76621d67-7119-4828-bb13-d84bc6173f44" alt="feedstreet" className="bd-placeholder-img opacity-50" width="100%" height="100%" />

                    <div className="container">
                        <div className="carousel-caption">
                            <h1>Streets to Shelters: Transforming Lives of Stray Animals</h1>
                            <p>Working tirelessly to rescue stray animals from the harsh realities of street life.</p>
                            <p><a className="btn btn-lg btn-primary" href="#">Donate</a></p>
                        </div>
                    </div>
                </div>
                <div className="carousel-item bg-dark">
                    <img src="https://firebasestorage.googleapis.com/v0/b/feed-street.appspot.com/o/feedstreet%2Fbanners%2F2_b3.png?alt=media&token=0ddd0dec-d377-4512-b52f-9309da0bc3dc" alt="feedstreet" className="bd-placeholder-img opacity-50" width="100%" height="100%" />

                    <div className="container">
                        <div className="carousel-caption text-end">
                            <h1>Strays to Safety: Advocating for Street Animals' Wellbeing</h1>
                            <p>Advocating for the rights and welfare of street animals through education and awareness campaigns</p>
                            <p><a className="btn btn-lg btn-primary" href="#">Contact Us</a></p>
                        </div>
                    </div>
                </div>
            </div>
            <button className="carousel-control-prev" type="button" data-bs-target="#myCarousel" data-bs-slide="prev">
                <span className="carousel-control-prev-icon" aria-hidden="true" />
                <span className="visually-hidden">Previous</span>
            </button>
            <button className="carousel-control-next" type="button" data-bs-target="#myCarousel" data-bs-slide="next">
                <span className="carousel-control-next-icon" aria-hidden="true" />
                <span className="visually-hidden">Next</span>
            </button>
        </div>

    )
}

export default Carousel