import React from 'react'
import Section from '../../section/Section'

function Rescue({ data }) {

  // rescue

  const rescueHeadingBody = (
    <h2 className="featurette-heading">{data.heading1}</h2>
  )

  const rescueParagraphBody = (
    <>
      <p className="lead">{data.para1}</p>
      <p className="lead">{data.para2}</p>
    </>
  )

  const rescueImageBody = (
    <img src="https://firebasestorage.googleapis.com/v0/b/feed-street.appspot.com/o/feedstreet%2Frescue%2Frescue%20(1).jpg?alt=media&token=1c31a0f0-88e5-41ee-8abf-8184c1cf21f3" alt="" className=" img-responsive bd-placeholder-img bd-placeholder-img-lg featurette-image img-fluid mx-auto" width={500} height={500} ></img>
  )
 

  // strelise
 
  const streliseHeadingBody = (
    <>
    <h2 className="featurette-heading">{data.heading2}</h2>
    <p>{data.subHeading1}</p>
    </>
  )

  const streliseParagraphBody = (
    <>
      <p className="lead">{data.para3}</p>
      <p className="lead">{data.para4}</p>
    </>
  )

  const streliseImageBody = (
    <img src="https://firebasestorage.googleapis.com/v0/b/feed-street.appspot.com/o/feedstreet%2Fgeneral%2Fstrelise.jpg?alt=media&token=8e86dccf-1fea-491b-a411-c7865c622004" alt="" className=" img-responsive bd-placeholder-img bd-placeholder-img-lg featurette-image img-fluid mx-auto" width={500} height={500} ></img>
  )


  //vaccinate
  const vaccinateHeadingBody = (
    <>
    <h2 className="featurette-heading">{data.heading3}</h2>
    </>
  )

  const vaccinateParagraphBody = (
    <>
      <p className="lead">{data.para5}</p>
    </>
  )

  const vaccinateImageBody = (
    <img src="https://firebasestorage.googleapis.com/v0/b/feed-street.appspot.com/o/feedstreet%2Fvaccinated%2Fvacinate%20(5).jpg?alt=media&token=8803b289-33a1-4dd7-b743-2d99eca0d182" alt="" className="img-responsive bd-placeholder-img bd-placeholder-img-lg featurette-image img-fluid mx-auto" width={500} height={500} ></img>
  )

  return (
    <div className="container">
      <Section headingBody={rescueHeadingBody} paragraphBody={rescueParagraphBody} imageBody={rescueImageBody} swap={false} />
      <Section headingBody={streliseHeadingBody} paragraphBody={streliseParagraphBody} imageBody={streliseImageBody} swap={true} />
      <Section headingBody={vaccinateHeadingBody} paragraphBody={vaccinateParagraphBody} imageBody={vaccinateImageBody} swap={false} />
    </div>
  )
}

export default Rescue