// import { useState, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
// import { Navigation } from "./components/navigation";
import Header from "./page-component/Header";
// import { Features } from "./components/features";
// import { About } from "./components/about";
// import { Services } from "./components/services";
// import { Gallery } from "./components/gallery";
// import { Testimonials } from "./components/testimonials";
// import { Team } from "./components/Team";
// import { Contact } from "./components/contact";
import JsonData from "./data/data.json";
import SmoothScroll from "smooth-scroll";
// import Home from "./components/home";
import Home from "./page-component/pages/home/Home";
import "./App.css";
import "./page-component/carousel.css"
import "bootstrap/dist/css/bootstrap.min.css";
// import Carousel from "./page-component/Carousel";
import Footer from "./page-component/Footer";
import AboutUs from "./page-component/pages/about-us/AboutUs";
import Rescue from "./page-component/pages/rescue/Rescue";
import Contact from "./page-component/pages/contact-us/Contact";
import Donation from "./page-component/pages/donation/Donation";
import Gallery from "./page-component/pages/gallery/GalleryPage";
import GalleryPage from "./page-component/pages/gallery/GalleryPage";
import AllServices from "./page-component/pages/services/AllServices";
import WhatsApp from "./page-component/pages/contact-us/WhatsApp";
export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
});

const App = () => {
  // const [landingPageData, setLandingPageData] = useState({});

  // useEffect(() => {
  //   // console.log("JsonData",JsonData)
  //   // JsonData && setLandingPageData(JsonData);
  //   console.log("ddd") 
  // }, [JsonData]);


  // console.log(JsonData)
  return (
    <Router>
      <Header data={JsonData?.Header} />
      <main className="container">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about-us" element={<AboutUs data={JsonData?.About} />} />
          <Route path="/rescue" element={<Rescue data={JsonData?.rescue} />} />
          <Route path="/service/:id" element={<AllServices />} />
          <Route path="/donation" element={<Donation />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/gallery" element={<GalleryPage data={JsonData?.Gallery} />} />
        </Routes>
      </main>
      <hr />

      <Footer />
    </Router>
  );
};

export default App;
