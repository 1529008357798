import React, { useMemo, useState } from 'react'
import Section from '../../section/Section'
import ModalLg from '../../modal/ModalLg'
import jsonData from "../../../data/data.json"
import { useParams } from 'react-router-dom'

const services = jsonData.services


function AllServices() {
    const param = useParams()
    const data = useMemo(() => services[param.id], [param.id])
    const [modalData, setModalData] = useState({})
    const [openModal, setOpenModal] = useState(false)

    const readMoreHandler = (item) => {
        setOpenModal(true)
        setModalData(item)
    }



    const headingBody = (<h2 className="featurette-heading">About Us </h2>)

    const paragraphBody = (
        <React.Fragment>
            <p className="lead">Orthovista rehab provide customized insole compresis stocking for partial who are suffering for foot and ankel pain or even knee pain.</p>

            <p className="lead">we focus to correct the biomechanice and alignment of the patient is body & offering some wellness and orthotic bracess</p>

            <p className="lead">we also deal with custom selicon restonatic and orthotic and prosthetic servises</p>
            <p className="lead">Our mission is to we provide personalized care for ears and every pahet who suffering from disability and postural issues.</p>
        </React.Fragment>
    )


    const imageBody = (
        <img src="https://firebasestorage.googleapis.com/v0/b/feed-street.appspot.com/o/feedstreet%2Fgeneral%2Fstrelise.jpg?alt=media&token=8e86dccf-1fea-491b-a411-c7865c622004" alt="" className="img-responsive bd-placeholder-img bd-placeholder-img-lg featurette-image img-fluid mx-auto" width={500} height={500} ></img>

    )

    return (
        <div className="container">

            <div className="row text-center my-5">
                <h1 className="text-capitalize">{data?.title} </h1>
                <h5 className="text-capitalize">{data?.sub_title}</h5>
                <p className="my-3">{data?.description}</p>
            </div>
            <div className="row">
                <div className="col-lg-12 d-flex justify-content-center">
                    {data?.image !== '' && <img src={data?.image} alt="orthovista" className="img-fluid" />}
                </div>
                {data?.sub_service?.map((item, i) => (
                    <div className="col-lg-4 col-md-4 col-sm-12 my-2" key={i}>
                        <div className="card">
                            <img className="card-img-top img-thumbnail" width={75} height={250} src={item?.image} alt={item?.title} />
                            <div className="card-body">
                                <h5 className="card-title text-capitalize">{item?.title}</h5>
                                <p className="card-text">{item?.description.substring(0, 120)} .....</p>
                                <button className="btn btn-outline-info btn-sm" onClick={() => readMoreHandler(item)}>Read More</button>
                            </div>
                        </div>
                    </div>
                ))}

                {openModal && <ModalLg modalData={modalData} setOpenModal={setOpenModal} />}

            </div>
        </div>
    )
}

export default AllServices