import React from 'react'
import WhatsApp from '../page-component/pages/contact-us/WhatsApp'

function Footer() {
    //     <footer className="container">
    //     <p className="float-end">Website Designed & Developed by <a href="https://www.linkedin.com/in/786abhishekverma" target="_blank" rel="noreferrer" >Abhishek Verma</a></p>

    //     <p>© Feed Street 2023, All Right Reserved.</p>
    // </footer>
    return (

        <div className="container">

            <footer className="d-flex flex-wrap justify-content-between align-items-center py-3 ">
                <p className="col-md-4 mb-0 text-muted">© 2023 Ortho Vista Rehab</p>
                <a href="/" className="col-md-4 d-flex align-items-center justify-content-center mb-3 mb-md-0 me-md-auto link-dark text-decoration-none">
                    <svg className="bi me-2" width={40} height={32}><use xlinkHref="#bootstrap" /></svg>
                </a>

                <WhatsApp />
            </footer>
        </div>


    )
}

export default Footer