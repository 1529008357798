import { Gallery } from "../../../components/gallery";
// import { Image } from "../../../components/image";
import JsonData from "../../../data/data.json"


const GalleryPage = (props) => {
  return (
    <div id='portfolio' className='text-center' dataMasonry='{"percentPosition": true }'>
      <div className='container'>
        <Gallery  data={JsonData?.genralGallery} title={"Gallery"} />
        <Gallery  data={JsonData?.donationGallery} />
        
      </div>
    </div>
  )
}

export default  GalleryPage
