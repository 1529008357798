import React from 'react'
import Carousel from '../../Carousel'
import Section from '../../section/Section'
import { Link } from 'react-router-dom'
import Banner from '../../banner/Banner'
import image1 from "../../../data/image/site-images/8531122.jpg"
import image2 from "../../../data/image/site-images/24644126.svg"
import fusionLogo from "../../../data/image/site-images/fusion-logo.png"


function Home() {

    //Our Cause
    const causeHeadingBody = (
        <h2 className="featurette-heading">Experience the OrthoVista Rehab Difference</h2>
    )

    const causeParagraphBody = (

        <p className="lead">Whether you're seeking relief from foot pain, exploring options for prosthetic restoration, or looking to improve your overall comfort and mobility, OrthoVista Rehab is here to help. Contact us today to schedule a consultation and take the first step towards a healthier, happier you.</p>
    )

    const causeImageBody = (
        <img src="https://firebasestorage.googleapis.com/v0/b/orthovistarehab-618cd.appspot.com/o/web-content%2Fhome2.jpeg?alt=media&token=30deb9ec-5a1e-49df-8fb0-d69f0aee3698" className="img-fluid" alt='pic' />
    )




    // our work
    const workHeadingBody = (
        <h2 className="featurette-heading">Our Mission</h2>
    )

    const workParagraphBody = (
        <>
            <p className="lead">At OrthoVista Rehab, our mission is to empower individuals to overcome mobility challenges and live life to the fullest. We are committed to providing compassionate care, innovative solutions, and personalized support to help our patients achieve their goals and enjoy an active and fulfilling lifestyle.
            </p>
            <ul>
                {/* <li><b>Feeding Programs</b>: Our dedicated team ensures that no hungry stomach is left unattended. With the support of our generous donors, we provide nutritious meals to street animals regularly.</li>
                <li><b>Medical Treatment</b>:  Sick and injured animals require urgent medical attention. Our veterinarians and volunteers treat various ailments, conduct vaccinations, and spay/neuter programs to reduce their suffering and improve their overall health.</li>
                <li><b>Shelter and Rescue</b>: We provide temporary shelters to animals in distress, ensuring they have a safe place to rest and recover while we seek loving forever homes for them. Our rescue efforts have saved countless lives from harsh conditions on the streets.</li>
                <li><b>Adoption Programs</b>: Finding loving families for our rescued animals is an essential part of our work. We meticulously screen potential adopters to ensure the best possible match for both the animals and their new families.</li>
                <li><b>Community Education</b>:We believe that change starts with awareness. Through workshops, seminars, and outreach programs, we educate the community about responsible pet ownership, kindness towards animals, and the importance of sterilization to control the stray animal population.</li> */}
            </ul>

        </>
    )

    const workImageBody = (
        <img src="https://firebasestorage.googleapis.com/v0/b/orthovistarehab-618cd.appspot.com/o/web-content%2Fhome-1.jpg?alt=media&token=a9986915-ff1b-4c7a-91bc-a799ce6a16ef" alt='mission' className="img-fluid" ></img>
    )






    // support matter
    // const supportHeadingBody = (
    //     <h1 className="text-capitalize">Collabrotion with fusion</h1>
    // )

    // const supportParagraphBody = (
    //     <>
    //     </>
    // )

    // const supportImageBody = (
    //     <img src="https://firebasestorage.googleapis.com/v0/b/feed-street.appspot.com/o/feedstreet%2Ffeeding%2Ffeed1.jpg?alt=media&token=5ea6e1c0-8008-4880-80b2-470c2ba234cc" alt="" className=" img-responsive bd-placeholder-img bd-placeholder-img-lg featurette-image img-fluid mx-auto" width={500} height={500} ></img>
    // )


    return (
        <React.Fragment>
            {/* <Carousel /> */}
            <div className="container">
                <div className="row">
                    <div className="col-lg-6 d-flex align-items-center my-5">
                        <div className=''>
                            <h1>Welcome to Orthovista Rehab</h1>
                            <h4>Step into a better life with our orthotic, prosthetic, and foot care services.</h4>
                        </div>

                        {/* <h1>
                            Welcome to Orthovista Rehab -  Our team is dedicated to providing exceptional care tailored to your needs. Discover how we can help you move forward.
                        </h1> */}
                    </div>
                    <div className="col-lg-6 my-5">

                        <img src={image1} alt="ortho" className="img-fluid" />
                    </div>

                </div>
                <Section headingBody={workHeadingBody} paragraphBody={workParagraphBody} imageBody={workImageBody} swap={false} />




                <Section headingBody={causeHeadingBody} paragraphBody={causeParagraphBody} imageBody={causeImageBody} swap={true} />

                {/* <div className="row">
                    <div className="col-lg-7 text-center my-5">
                        <img src="https://firebasestorage.googleapis.com/v0/b/orthovistarehab-618cd.appspot.com/o/web-content%2Fhome-3.avif?alt=media&token=ff06a844-320e-4956-957c-97e0c0368b3a " alt="ortho" className="img-fluid" />
                    </div>
                    <div className="col-lg-5 text-center my-5">
                        <h1 className="text-capitalize mb-5">Collabrotion with fusion</h1>
                        <img src={fusionLogo} alt="fusion" className="img-fluid" />
                    </div>

                </div> */}
                {/* <Section headingBody={supportHeadingBody} paragraphBody={supportParagraphBody} imageBody={supportImageBody} swap={false} /> */}
            </div>

        </React.Fragment>
    )
}

export default Home