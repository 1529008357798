import React from 'react'
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import image2 from "../../../data/image/site-images/24644126.svg"
import Section from '../../section/Section'


function Contact() {

  // our work
  const workHeadingBody = (
    <h1>Contact Us</h1>
  )

  const workParagraphBody = (
    <div className="card w-100 my-5">
      <div className="card-body">
        {/* <h5 className="card-title">Card title</h5> */}
        <table className="table">
          <tbody>
            <tr>
              <th scope="row"><i className='fa fa-phone' /></th>
              <td>
                +91 8178120937
              </td>
            </tr>
            <tr>
              <th scope="row"><i className='fa fa-whatsapp' /></th>
              <td>
                <a href="https://api.whatsapp.com/send?phone=918178120937&text=Hi%2C%20Appointment%20inquiry.%20Please%20advise.%20Thanks." target="_blank">
                  +91 8178120937
                </a>

              </td>
            </tr>
            <tr>
              <th scope="row"><i className='fa fa-envelope' /></th>
              <td>orthovistarehab@gmail.com</td>

            </tr>
            <tr>
              <th scope="row"><i className='fa fa-map-marker ' /></th>
              <td colSpan={2}> J- 231, Pocket J, Sarita Vihar, New Delhi, Delhi 110076</td>
            </tr>
          </tbody>
        </table>

        <div>
          <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3505.100589614886!2d77.2952719!3d28.536696100000004!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390ce7e5e8f202a9%3A0x34a22942104a9b45!2sOrthoVistaRehab!5e0!3m2!1sen!2sin!4v1731748171745!5m2!1sen!2sin" width={700} height={450} style={{ border: 0 }} allowFullScreen loading="lazy" referrerPolicy="no-referrer-when-downgrade" />



        </div>

      </div>
    </div>

  )

  const workImageBody = (

    <img src={image2} alt='mission' ></img>

  )


  return (
    <div className="container">
      <Section headingBody={workHeadingBody} paragraphBody={workParagraphBody} imageBody={workImageBody} swap={false} />
      {/* <h2>Contact Us</h2>

      <div className="row d-flex justify-content-center">
        <div className="col-lg-10 col-md-10 col-sm-12 bg-light">
        
          <p>We receive a substantial number of messages and make every effort to respond to as many as possible. However, due to time constraints, we regret that we may be unable to address all of them. If you require assistance with an injured animal, please do not hesitate to call us</p>
          <div className="card mb-3">
            <div className="card-body">
              <div className="d-lg-flex">
                <div className="col-lg-6 col-md-12 col-sm-12 text-start">
                  <h4 className="mb-1">Animal Helpline Number</h4>
                  <p className="mt-1"><i className="fa-solid fa-phone"></i> 9990568935</p>
                  
                </div>
                
                
                <div className="col-lg-6 col-md-12 col-sm-12 text-start">
                
                  <h4 className="mb-1">Adoption</h4>
                  <p className="mb-1">To adopt an animal from us </p>
                  <p><i className="fa-brands fa-whatsapp" style={{color: "#58e10f"}}></i> <a href="https://api.whatsapp.com/send?phone=9990568935">9990568935</a>  </p>
                  <hr/>
                  <h6 className="mt-2">Planning to visit or sending supplies </h6>
                  <p><i className="fa fa-location-dot"></i> House No. : A-282/17,  Beribagh, Aali vihar, Sarita Vihar, New Delhi, 110076</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
    </div>
  )
}

export default Contact
