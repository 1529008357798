import React from 'react'
import banner from "../../data/image/site-images/031020_Medical_7.png"

function Banner() {
    return (
        <div className="row">
            <div className="col-lg-12">
                <img src={banner} alt="banner" className="img-fluid banner-home" />
            </div>
        </div>
    )
}

export default Banner