import React from 'react'
import { motion } from 'framer-motion'
// motion

function Section({ headingBody, paragraphBody, imageBody, swap }) {
    return (
        <React.Fragment>
            <motion.div
                className="row featurette mt-5">
                <div className={`col-lg-7 col-md-12 col-sm-12  ${!swap && 'order-md-2'}`} style={{ height: '100%' }}>
                    {headingBody}
                    {paragraphBody}
                </div>
                <motion.div
                    whileHover={{ scale: 1.1 }}
                    whileTap={{ scale: 0 }}
                    transition={{ ease: "easeOut", duration: 1 }}
                    className={`col-lg-5 col-md-12 col-sm-12 false justify-content-center d-flex ${!swap && 'order-md-1'}`} style={{ height: '100%' }}>
                    {imageBody}
                </motion.div>
            </motion.div>
            <hr className="featurette-divider" />
        </React.Fragment>
    )
}

export default Section