import React from 'react'

function WhatsApp() {
    return (
        <a href="https://api.whatsapp.com/send?phone=918178120937&text=Hi%2C%20Appointment%20inquiry.%20Please%20advise.%20Thanks." class="float-whatsapp" target="_blank">
            <i class="fa fa-whatsapp my-float"></i>
        </a>
    )
}

export default WhatsApp