import React from 'react'
import { Gallery } from '../../../components/gallery'
import JsonData from "../../../data/data.json"
// {console.log(JsonData?.donationGallery)}
function Donation() {
  return (
    <div className="container text-center mt-2">
      <h1>Donate</h1>

      <div className="row d-flex justify-content-center">
        <div className="col-lg-10 col-md-10 col-sm-12 bg-light">
        
          <h6 className="text-bold">Note : Donations made to feed streets can be claimed as a deduction under section 80g of the income tax Act. </h6>
          <div className="card mb-3">
            <div className="card-body">
              <div className="d-lg-flex">

                <div className="col-lg-6 col-md-12 col-sm-12 text-start ">
                  <h5 className="mb-1">Bank Transfer Detail</h5>
                  <p className="mb-0">Accoutn Holder Name : FEED STREET TRUST</p>
                  <p className="mb-0">Account Number : 060021010000015</p>
                  <p className="mb-0">Ifcs Code : UBIN0906000</p>


                  <h5 className="mt-5">Paytm / PhonePe / Google Pay</h5>
                  <p className="mb-0">Paytm/ PhonePe: 9990568935 </p>
                  <p className="mb-0">Google Pay : 7838744654</p>

                </div>

                <div className="col-lg-6 col-md-12 col-sm-12 text-start">
                  <hr/>
                  <h5>Your money will go towards</h5>
                    <ul>
                      <li>Medicines and medical checkups for animals </li>
                      <li>Food for animals </li>
                      <li>Transport expenses for animals rescues </li>
                    </ul>
                </div>
              </div>
            </div>
          </div>

          {/* <Gallery data={JsonData?.Gallery}/> */}
         
          <Gallery data={JsonData?.donationGallery} title={"Donate Today"}/>
        </div>
      </div>
    </div>
  )
}

export default Donation