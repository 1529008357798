import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';

function ModalLg({ modalData, setOpenModal }) {
    // console.log(props)
    const [lgShow, setLgShow] = useState(true);

    const closeHandler = () => {
        setOpenModal(false)
        setLgShow(false)
    }

    return (
        <React.Fragment>
            <Modal
                size="lg"
                show={lgShow}
                onHide={() => closeHandler()}
                aria-labelledby="example-modal-sizes-title-lg"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="example-modal-sizes-title-lg" className="text-capitalize">
                        {modalData?.title}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>{modalData?.description}</Modal.Body>
            </Modal>
        </React.Fragment>
    );
}

export default ModalLg;