import React from 'react'
import logo from "../../src/data/image/site-images/logo.png"
import { useState } from 'react'
import { useEffect } from 'react'
import { Link } from 'react-router-dom'
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Classes from './Header.module.css'


const Header = ({ data }) => {
    const [scroll, setScroll] = useState(false)
    useEffect(() => {
        window.addEventListener("scroll", () => {
            // console.log(window.scrollY)
            if (window.scrollY > 77) {
                setScroll(true)
            } else {
                setScroll(false)
            }
            // setScroll(window.scrollY > 77)

        })
    }, [])
    console.log("scroll", scroll)


    return (
        <nav className={`navbar navbar-expand-md  text-black fixed-top position-sticky ${scroll ? Classes.navbar_inner : 'bg-light'}`}>
            <div className="container">
                <Link className={`navbar-brand ${Classes.mobile_nav}`} to="/">
                    <img src={logo} className="site-logo" alt="feed street" />
                </Link>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon" />
                </button>
                <div className="collapse navbar-collapse" id="navbarCollapse">
                    <ul className="navbar-nav me-auto mb-2 mb-md-0 justify-content-end w-100">
                        {data?.menu.map(item => (
                            item.linkUrl === "" ?
                                <li className="nav-item dropdown">
                                    <a className="nav-link dropdown-toggle" href={false} role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                        {item.linkName}
                                    </a>
                                    <ul className="dropdown-menu">
                                        {item.subMenu.map(sm => (
                                            <li className="nav-item">
                                                <Link className="dropdown-item" aria-current="page" to={sm.linkUrl}>{sm.linkName}</Link>
                                            </li>
                                        ))}
                                    </ul>
                                </li>
                                :
                                <li className="nav-item">
                                    <Link className="nav-link active" aria-current="page" to={item.linkUrl}>{item.linkName}</Link>
                                </li>
                        ))}
                    </ul>
                </div>
            </div>
        </nav>

    )
}

export default Header