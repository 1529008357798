import React from 'react'
import Section from '../../section/Section'

function AboutUs() {

    const headingBody = (
        <React.Fragment>
            <h1 className="">About Us </h1>
        </React.Fragment>

    )

    const paragraphBody = (
        <React.Fragment>
            <p className="">Orthovista Rehab specializes in personalized solutions for foot, ankle, and knee pain, offering custom insoles tailored to individual needs. Our focus on biomechanics and body alignment is complemented by orthotic braces for optimal support. Additionally, we provide services in custom silicone restoration and upper and lower limb prostheses.
            </p>
            <p className="">
                <strong>Our mission</strong> is to deliver personalized care to each patient experiencing disability or postural issues, aiming to restore confidence and mobility. Through advanced techniques and compassionate guidance, we strive to empower individuals to lead fulfilling lives. At Orthovista Rehab, we believe in addressing not just symptoms, but the root causes of discomfort, ensuring long-term relief and well-being.</p>

            <p className="">
                With a commitment to excellence and innovation, we stand as a trusted partner on the journey to improved health and mobility. Let us help you take the first step towards a more comfortable, active lifestyle.

            </p>
        </React.Fragment>
    )


    const imageBody = (
        <img src="https://firebasestorage.googleapis.com/v0/b/orthovistarehab-618cd.appspot.com/o/web-content%2Faboutus.webp?alt=media&token=25d3c08e-0a95-4928-896a-44b54d879111" alt="" className="img-responsive bd-placeholder-img bd-placeholder-img-lg featurette-image img-fluid mx-auto" ></img>

    )

    return (
        <div className="container">
            <Section
                headingBody={headingBody}
                paragraphBody={paragraphBody}
                imageBody={imageBody}
                swap={false} />
        </div>
    )
}

export default AboutUs