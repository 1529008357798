import { Image } from "./image";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";

export const Gallery = (props) => {

  const items = props?.data?.map((d, i) => (
    <img
      key={i}
      src={d?.smallImage}
      style={{ width: "100%", borderRadius: "8px" }}
    />
  ));
  
  return (
    <div id='portfolio' className='text-center'>
      <div className='container'>
        <div className='section-title'>
          <h2>{props?.title}</h2>
          {/* <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit duis sed
            dapibus leonec.
          </p> */}
        </div>
        <div className="App">
      <ResponsiveMasonry
        columnsCountBreakPoints={{ 300: 2, 500: 2, 700: 2, 900: 3 }}
      >
        <Masonry gutter="10px" columnsCount={3} >{items}</Masonry>
      </ResponsiveMasonry>
    </div>
        {/* <div className='row'>
          <div className='d-flex flex-wrap justify-content-between'>
          <Masonry columnsCount={3} gutter="10px">
        
                {props.data
                ? props.data.map((d, i) => (
                  <div key={`${d?.title}-${i}`} className='col-sm-6 col-md-4 col-lg-4 me-auto p-2'>
                    <Image title={d?.title} largeImage={d?.largeImage} smallImage={d?.smallImage} />
                  </div>
                ))
                : 'Loading...'}
              </Masonry>
          </div>
        </div> */}
      </div>
    </div>
  )
}
